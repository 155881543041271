<template>
  <div class="blank-page">
    <div class="blank-img-box">
      <img :src="blank.imgUrl" />
      <span>{{ blank.text }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "blank-page",
  props: {
    blank: {
      default: {},
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.blank-page {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .blank-img-box {
    color: #656565;
    font-size: 18px;
    text-align: center;
    width: 246px;
    img {
      display: block;
      width: 100%;
    }
  }
}
</style>