<template>
  <div class="goods-list">
    <div class="box-head">
      <div class="box-head-address" @click.stop="isAddress= true">
        <img @click.stop="toHome()" class="img-r" src="@/assets/seize-img/info-left.png" alt="">

        <img class="img-1" src="@/assets/seize-img/address.png" alt="">
        <div class="fs-14 c-fff">{{addText}}</div>
        <img class="img-2" src="@/assets/seize-img/down.png" alt="">
      </div>

      <div class="box-head-serch">
        <input v-model="fromData.good_name" type="text" placeholder="请输入商品名称">
        <div class="serch-button" @click="serech()">搜索</div>
      </div>

      <div class="box-head-select">
        <div class="" @click="isClass=true">{{fromData.class_id?classText:'全部分类'}}<img src="@/assets/seize-img/index-down.png" alt=""></div>
        <div class="" @click="isSort=true">{{orderByList[fromData.order_by * 1].name}}<img src="@/assets/seize-img/index-down.png" alt=""></div>
      </div>
    </div>


    <div style="height: 20px;"></div>
    <div v-if="list.length>0" class="box-list" @scroll="listScroll($event)">
      <div class="box-1" v-for="(item,i) in list" :key="i" @click="toUrl(2,item)">
        <img class="img-left" :src="item.cover_img" alt="">
        <div class="info-box">
          <div class="info-box-1">
            <span class="info-box-icon">小时达</span>
            {{item.good_name}}
          </div>
          <div class="info-box-2">原价¥{{item.original_price}}</div>
          <div class="info-box-3">
            <span>￥</span>{{item.price}}
            <div class="info-box-qg">立即抢购</div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="box-list">
      <BlankPage :blank="{text:'暂无数据'}"></BlankPage>
    </div>


    <div class="box-nav phone-ios">
      <div class="nav-box-a">
        <img src="@/assets/seize-img/zc.png" alt="">
        <div class="c-red">主场</div>
      </div>
      <div class="nav-box-a" @click="toUrl(1)">
        <img src="@/assets/seize-img/gwc.png" alt="">
        <div class="c-999">购物车</div>
      </div>
    </div>


    <div v-if="isSort" class="pop-sort phone-ios" @click.stop="isSort=false">
      <div class="sort-nav">
        <div class="sort-nav-title">选择排序方式</div>
        <div class="sort-nav-box" :class="item.id === fromData.order_by?'c-red':''"
        v-for="(item,i) in orderByList" :key="i" @click.stop="select(1,item)">{{item.name}}</div>
      </div>
    </div>

    <div v-if="isAddress" class="pop-sort phone-ios" @click.stop="isAddress=false">
      <div class="sort-nav">
        <div class="sort-nav-title">选择地址</div>
        <div class="sort-nav-box" :class="item.id === fromData.address_id?'c-red':''"
        v-for="(item,i) in addressList" :key="i" @click.stop="select(2,item)">{{item.address}}</div>
      </div>
    </div>

    <div v-if="isClass" class="pop-sort phone-ios" @click.stop="closeIsClass()">
      <div class="sort-nav">
        <div class="sort-nav-title">
          {{classTitle}}
          <div class="up-buttom" v-if="classFlay > 1" @click.stop="upFun()">上一级</div>
        </div>
        <div class="sort-nav-box" v-if="classFlay === 1" @click.stop="select(3,'')">全部分类</div>
        <div class="sort-nav-box"
        v-for="(item,i) in onlyClassList" :key="i" @click.stop="select(3,item)">{{item.class_name}}</div>
      </div>
    </div>
  </div>
</template>

<script>
  import BlankPage from '@/components/blank-page/blank-page.vue';

  import {getAddressList,} from '@/api/member.js';
  import {GoodsClass,} from '@/api/goods.js'
  import {robList,} from '@/api/rob.js'

  export default {
    components:{BlankPage,},
    data(){
      return{
        list:[],
        addText:'请选择地址',
        classText:'',
        total:0,
        fromData:{
          good_name:'',
          address_id:'',
          class_id:'',
          order_by:'',
          page:1,
        },
        orderByList:[
          {id:'',name:'综合排序'},
          {id:'1',name:'销量正序'},
          {id:'2',name:'销量倒序'},
          {id:'3',name:'价格正序'},
          {id:'4',name:'价格倒序'},
        ],
        addressList:[],
        classList:[],
        onlyClassList:[],
        twoClassList:[],//2级
        classTitle:'选择分类',
        isSort:false,
        isAddress:false,
        isClass:false,
        classFlay:1,
      }
    },
    mounted() {
      this.getAddressList()
      this.getGoodsClass()

    },
    methods:{
      toHome(){
        this.$router.push({path:"/home",replace:true,})
      },
      
      getAddressList(){
        getAddressList().then((res)=>{
          if(res.code === 1){
            this.addressList = res.data
            this.fromData.address_id = this.addressList[0].id
            this.addText = this.addressList[0].city_names + this.addressList[0].address
            if(this.fromData.address_id){
              this.getList()
            }
          }
        })
      },
      getGoodsClass(){
        GoodsClass({type:1}).then((res)=>{
          if(res.code === 1){
            this.classList = res.data
            this.onlyClassList = this.classList
          }
        })
      },
      getList(){
        robList(this.fromData).then((res)=>{
          if(res.code === 1){
            this.total = res.data.total
            this.list = [...this.list,...res.data.list]
          }
        })
      },
      resetPage(){
        this.list = []
        this.fromData.page = 1
        this.getList()
      },
      serech(){
        if(this.fromData.good_name){
          this.resetPage()
        }
      },
      listScroll(e){
        let el = e.target
        if(el.scrollTop + el.clientHeight >= el.scrollHeight){
          if(this.list.length < this.total){
            this.fromData.page += 1
            this.getList()
          }
        }
      },
      upFun(){
        this.classFlay -= 1
        if(this.classFlay === 1){
          this.onlyClassList = this.classList
        }else if(this.classFlay === 2){
          this.onlyClassList = this.twoClassList
        }
      },
      select(type,item){
        if(type === 1){
          // 选择排序
          if(item.id!=this.fromData.order_by){
            this.fromData.order_by = item.id
            this.resetPage()
            this.isSort = false
          }
        }else if(type === 2){
          // 选择地址
          if(item.id!=this.fromData.address_id){
            this.fromData.address_id = item.id
            this.addText = item.city_names + item.address
            this.resetPage()
            this.isAddress = false
          }
        }else if(type === 3){
          if(item){
            if(this.classFlay !== 3){
              this.classFlay += 1
              this.classTitle = item.class_name
              this.onlyClassList = item.child_class
              if(this.classFlay === 2){
                this.twoClassList = item.child_class
              }
            }else{
              this.fromData.class_id = item.id
              this.classText = item.class_name
              this.resetPage()
              this.closeIsClass()
            }
          }else{
            this.fromData.class_id = ''
            this.classText = '全部分类'
            this.resetPage()
            this.closeIsClass()
          }
        }
      },
      closeIsClass(){
        this.classFlay = 1
        this.twoClassList = []
        this.classTitle = '全部分类'
        this.onlyClassList = this.classList
        this.isClass = false
      },
      toUrl(type,item){
        if(type === 1){
          // 去购物车
          this.$router.push({path:'/seize-shop-cart'})
        }else if(type === 2){
          // 去抢购
          this.$router.push({path:'/seize-goods-det',query:{goods_id:item.id}})
        }
      },
    },
  }
</script>

<style scoped lang="less">
  .goods-list{
    width: 100%;
    height: 100vh;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    position: relative;

    .pop-sort{
      width: 100%;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.5);
      position: absolute;
      top: 0;
      left: 0;
      .sort-nav{
        position: absolute;
        bottom: 0;
        background-color: #fff;
        width: 100%;
        height: 50%;
        overflow: scroll;
        border-radius: 20px 20px 0 0;
        box-sizing: border-box;
        padding: 10px;

        .sort-nav-title{
          font-size: 16px;
          font-weight: 600;
          height: 48px;
          line-height: 48px;
          text-align: center;
          position: relative;
          .up-buttom{
            position: absolute;
            font-size: 12px;
            color: #ccc;
            left: 20px;
            top: 0;
          }
        }
        .sort-nav-box{
          width: 100%;
          // height: 44px;
          // line-height: 44px;
          box-sizing: border-box;
          padding: 20px;
          font-size: 14px;
          text-align: center;
          border-top: 1px solid #ececec;
        }
      }
    }

    .phone-ios{
      padding-bottom: constant(safe-area-inset-bottom);
      padding-bottom: env(safe-area-inset-bottom);
    }
    .c-red{
      color: #ED301D;
    }
    .c-999{
      color: #999999;
    }
    .c-fff{
      color: #fff;
    }
    .fs-14{
      font-size: 14px;
    }

    .box-head{
      width: 100%;
      height: 116px;
      background: url('../../assets/seize-img/bg.png') no-repeat;
      background-size: 100% 100%;
      box-sizing: border-box;
      padding: 12px;
      position: relative;
      .box-head-address{
        display: flex;
        align-items: center;
        margin-bottom: 13px;
        .img-r{
          width: 16px;
          height: 16px;
          margin-right: 20px;
        }
        .img-1{
          width: 16px;
          height: 16px;
        }
        .img-2{
          width: 22px;
          height: 22px;
        }
        div{
          margin: 0 5px;
          width: 100px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .box-head-serch{
        position: relative;
        input{
          width: 100%;
          height: 32px;
          background-color: #FEC7C3;
          border: none;
          border-radius: 30px 30px 30px 30px;
          box-sizing: border-box;
          padding: 0 60px 0 32px;
          font-size: 14px;
        }
        .serch-button{
          width: 54px;
          height: 26px;
          background: linear-gradient(270deg, #FF431D 0%, #FF8632 100%);
          border-radius: 30px 30px 30px 30px;
          position: absolute;
          right: 2px;
          top: 3px;
          text-align: center;
          line-height: 28px;
          color: #fff;
          font-size: 14px;font-weight: 400;
        }
      }
      .box-head-select{
        width: 100%;
        height: 44px;
        position: absolute;
        background: #fff;
        border-radius: 16px 16px 0 0;
        bottom: -20px;
        left: 0;
        display: flex;
        div{
          width: 50%;
          height: 44px;
          text-align: center;
          line-height: 44px;
          font-size: 14px;
        }
        img{
          width: 16px;
          height: 16px;
          margin-left: 4px;
        }
      }
    }
    .box-list{
      width: 100%;
      flex: 1;
      overflow-y: scroll;
      box-sizing: border-box;
      padding: 0 12px;
      .box-1{
        margin: 10px 0;
        display: flex;

        .img-left{
          width: 140px;
          height: 140px;
          border-radius: 8px;
          margin-right: 12px;
        }
        .info-box{
          flex: 1;
          .info-box-1{
            height: 36px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            font-size: 14px;
            margin-bottom: 40px;
            .info-box-icon{
              width: 56px;
              height: 18px;
              background: linear-gradient(135deg, #FF383F 0%, #FF7F26 100%);
              border-radius: 3px;
              text-align: center;
              line-height: 18px;
              font-size: 12px;
              color: #fff;
            }
          }
          .info-box-2{
            font-size: 12px;
            color: #999999;
            margin-bottom: 6px;
          }
          .info-box-3{
            width: 100%;
            height: 40px;
            line-height: 40px;
            background: #FFE6C2;
            border-radius: 6px;
            position: relative;
            font-weight: bold;
            color: #ED301D;
            font-size: 22px;
            box-sizing: border-box;
            padding-left: 5px;
            .info-box-qg{
              position: absolute;
              right: 0;
              top: 0;
              width: 93px;
              height: 40px;
              line-height: 40px;
              text-align: center;
              font-weight: 600;
              color: #FFFFFF;
              font-size: 14px;
              box-sizing: border-box;
              padding-left: 10px;
              background: url('../../assets/seize-img/box-bg.png') no-repeat;
              background-size: 100% 100%;
            }
            span{
              font-size: 12px;
            }
          }
        }
      }
    }
    .box-nav{

      width: 100%;
      height: 50px;
      border-top: 1px solid #EEEEEE;
      display: flex;
      align-items: center;
      .nav-box-a{
        width: 50%;
        height: 100%;
        text-align: center;
        img{
          width: 24px;
          height: 24px;
          margin-top: 5px;
        }
        div{
          font-size: 10px;
        }
      }
    }
  }
</style>
